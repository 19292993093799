import { actions } from '@containers/App/slice';
import { I_Notification } from '@containers/App/types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useNotification = () => {
  const dispatch = useDispatch();

  const onOpenNotification = useCallback(
    (noti: I_Notification) => {
      dispatch(actions.setNotification(noti));
    },
    [dispatch],
  );

  const onCloseNotification = useCallback(() => {
    dispatch(actions.setNotification({ show: false }));
  }, [dispatch]);

  const onValidateField = useCallback(
    (
      key: string,
      value: any,
      typeValidate:
        | 'isEmail'
        | 'isNumber'
        | 'notEmpty'
        | 'isPhoneNumber'
        | 'isArray',
    ) => {
      let isValid = false;
      switch (typeValidate) {
        case 'isEmail':
          if (
            String(value)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              )
          )
            isValid = true;
          else {
            isValid = false;
            onOpenNotification({
              show: true,
              title: 'Warning',
              message: `${key} is not valid email`,
              type: 'warning',
            });
          }
          break;
        case 'isNumber':
          if (value.match(/^[0-9]*$/)) isValid = true;
          else {
            isValid = false;
            onOpenNotification({
              show: true,
              title: 'Warning',
              message: `${key} is not valid nunber`,
              type: 'warning',
            });
          }
          break;
        case 'isPhoneNumber':
          if (value.match(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/))
            isValid = true;
          else {
            isValid = false;
            onOpenNotification({
              show: true,
              title: 'Warning',
              message: `${key} is not phone number`,
              type: 'warning',
            });
          }
          break;
        case 'notEmpty':
          if (value) isValid = true;
          else {
            isValid = false;
            onOpenNotification({
              show: true,
              title: 'Warning',
              message: `${key} is not empty`,
              type: 'warning',
            });
          }
          break;
        case 'isArray':
          if (value.length === 0) {
            isValid = false;
            onOpenNotification({
              show: true,
              title: 'Warning',
              message: `${key} is not empty`,
              type: 'warning',
            });
          }
        default:
          break;
      }
      return isValid;
    },
    [onOpenNotification],
  );

  return useMemo(
    () => ({
      onOpenNotification,
      onCloseNotification,
      onValidateField,
    }),
    [onOpenNotification, onCloseNotification, onValidateField],
  );
};
