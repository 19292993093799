import React from 'react';
import { useSelector } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { selectNotification } from '@containers/App/selectors';
import { useNotification } from '@utils/hooks/useNotification';

const ToastWrapper: React.FC = () => {
  const notification = useSelector(selectNotification);

  const { onCloseNotification } = useNotification();

  return (
    <ToastContainer
      containerPosition="fixed"
      position="bottom-end"
      style={{ zIndex: 100000 }}
    >
      <Toast
        show={notification?.show}
        className="d-inline-block m-1"
        autohide
        bg={notification?.type}
        onClose={onCloseNotification}
      >
        <Toast.Header>
          <img
            src="/static/img/logo.png"
            className="rounded me-2"
            alt="Goterest"
            style={{ width: '120px' }}
          />
          <strong className="me-auto">{notification?.title}</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{notification?.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastWrapper;
